@import './mod/reset';
@import './mod/common';
@import './mod/utils';
@import './mod/head';
@import './mod/foot';

body {
    color: #666;
    padding: 0;
    margin: 0;
    font-family: "museo-sans-rounded", sans-serif;
    font-weight: 300;
    font-style: normal;
    line-height: 1;
    position: relative;
    cursor: "default";
}
html, body {
    font-size: 100%;
}

._404 {
    text-align: center;
		width: 100%;
    margin: 80px auto;
    max-width: 62.5em;
    >*{
      margin-left: auto;
	    margin-right: auto;
	    float: none;
    }
    .title {
	    line-height: 1.4;
	    span{
	    	font-size: 5em;
		    display: block;
		    font-weight: bold;
		    line-height: 1;
		    margin-bottom: 20px;
	    }
    }
    p{
    	margin-top: 15px;
    	margin-bottom: 0;
    	font-size: 1.4em;
    }
}

@media only screen and (min-width: 768px){
	h1 {
    font-size: 2.375em;
    line-height: 1.4;
    font-style: normal;
    color: #444;
    text-rendering: optimizeLegibility;
    margin-top: 0;
	}
	._404 > * {
    position: relative;
    width: 83.33333%;
	}
}

@media (min-width: 641px) {
	p {
    font-size: 1.1em;
    line-height: 1.5;
	}
}
